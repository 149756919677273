<template>
  <main>
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bg-gray-medium py-8">
      <div class="container">
        <div class="w-full  px-4">
          <header-h2
            :content="page.title"
            color="black"
            casing="uppercase"
            family="header"
            align="center"
          />
        </div>
      </div>
    </section>
    <section class="bg-gray-medium py-4 news-filter">
      <div class="w-full text-center">
        <button
          @click="selectedTag = tag"
          :key="tag.id"
          v-for="tag in tags"
          :class="
            `button button--${
              selectedTag.name === tag.name ? 'primary' : 'secondary'
            } mx-2`
          "
        >
          <span>{{ tag.name }}</span>
        </button>
      </div>
    </section>
    <section class="bg-gray-medium py-8">
      <div class="container">
        <client-only>
          <masonry
            :cols="{ default: 3, 1024: 2, 768: 1 }"
            :gutter="0"
            class="laptop:-ml-4 flex flex-wrap px-4 tablet:px-0"
          >
            <div
              v-for="(post, index) in posts"
              :key="index"
              class="w-full mb-8"
              style="padding: 0 !important"
            >
              <div
                v-if="
                  !selectedTag.id ||
                    (selectedTag.id &&
                      selectedTag.id === post.acf.news_category)
                "
              >
                <div v-if="post.media">
                  <news-item
                    :title="post.title"
                    :content="post.excerpt"
                    :slug="post.slug"
                    :image-url="post.media.medium"
                  />
                </div>
                <div v-else>
                  <news-item
                    :title="post.title"
                    :content="post.excerpt"
                    :slug="post.slug"
                  />
                </div>
              </div>
            </div>
          </masonry>
        </client-only>
      </div>
    </section>
  </main>
</template>

<script>
import NewsItem from '../../components/news/Item'
import BarBreadcrumbs from '../../components/bars/Breadcrumbs'
import wordpress from '~/service/wordpress'

export default {
  name: 'Service',
  components: { BarBreadcrumbs, NewsItem },

  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Air Conditioning Parts - AP Air LTD.'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air LTD.' },
        {
          property: 'og:article:published_time',
          content: this.posts[0].date
        },
        {
          property: 'og:article:modified_time',
          content: this.posts[0].date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.posts[0].date_modified
        }
      ]
    }
  },
  data() {
    return {
      selectedTag: {},
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('menu.news'),
          url: 'news'
        }
      ]
    }
  },
  async asyncData({ app }) {
    const [posts, page, tags] = await Promise.all([
      wordpress.get(`/${app.i18n.t('endpoints.cat_news')}`, {
        params: {
          order_by: 'menu_order',
          order: 'desc',
          per_page: 99999
        }
      }),
      wordpress.get(`page/${app.i18n.t('endpoints.news')}`, {
        params: {
          order_by: 'menu_order',
          order: 'desc',
          per_page: 99999
        }
      }),
      wordpress.get(`https://apair-cms.apairltd.com/wp-json/wp/v2/tags`)
    ])

    const newTags = []

    tags.data.map((tag) => {
      if (tag.slug.endsWith(app.i18n.locale)) {
        newTags.push(tag)
      }
    })

    return {
      posts: posts.data,
      page: page.data,
      tags: newTags
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'News',
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>

<style>
div {
  word-wrap: break-word;
}
</style>
