<template>
  <main id="news_item">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bg-gray-medium text-white py-16">
      <div class="container">
        <div class="flex">
          <div class="w-full">
            <header-h2
              :content="post.title"
              casing="uppercase"
              align="center"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="bg-gray-medium pb-16">
      <div class="container">
        <div class="flex">
          <div class="w-full tablet:w-7/10 tablet:mx-auto px-4">
            <div class="news__item">
              <div v-if="post.media" class="news__image -mt-8">
                <img
                  :src="post.media.large"
                  :alt="post.title"
                  class="mx-auto"
                />
              </div>
              <div class="news__content">
                <div v-html="post.content" />

                <div class="mt-8">
                  <button-tertiary
                    :path="'news'"
                    :text="$t('components.news.show_all')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BarBreadcrumbs from '../../components/bars/Breadcrumbs'
import wordpress from '~/service/wordpress'

export default {
  name: 'Service',
  components: { BarBreadcrumbs },
  head() {
    const title =
      this.post.yoast.yoast_wpseo_title === ''
        ? this.post.title + ' - Air Conditioning Parts - AP Air LTD.'
        : this.post.yoast.yoast_wpseo_title
    const description =
      this.post.yoast.yoast_wpseo_metadesc === ''
        ? this.post.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.post.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'post' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air LTD.' },
        {
          property: 'og:article:published_time',
          content: this.post.date
        },
        {
          property: 'og:article:modified_time',
          content: this.post.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.post.date_modified
        },

        {
          property: 'og:image',
          content: this.post.media.medium
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.post.media.medium
        }
      ]
    }
  },
  async asyncData({ params, app }) {
    const [post] = await Promise.all([wordpress.get(`post/${params.slug}`)])

    return {
      post: post.data,
      breadcrumbs: [
        {
          name: app.i18n.t('menu.home'),
          url: 'index'
        },
        {
          name: app.i18n.t('menu.news'),
          url: 'news'
        },
        {
          name: post.data.title,
          url: {
            name: 'news-slug',
            params: {
              slug: post.data.slug
            }
          }
        }
      ]
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.post.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>
