<template>
  <main id="products">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap flex-col-reverse tablet:flex-row">
          <div class="w-full tablet:w-1/4 px-4">
            <sidebar />
          </div>
          <div class="w-full tablet:w-3/4">
            <div id="search_products">
              <section>
                <div class="flex flex-wrap">
                  <div class="w-full px-4">
                    <div v-for="product in products">
                      <product-search-item
                        :data="product"
                        :link="{
                          name: 'new-products-product',
                          params: {
                            product: $slugify(product.link)
                          }
                        }"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import BarBreadcrumbs from '../../components/bars/Breadcrumbs'
import portal from '../../service/portal'
import ProductSearchItem from '../../components/products/SearchItem'
import Sidebar from '../../components/bars/Sidebar'
export default {
  name: 'Products',
  components: { Sidebar, ProductSearchItem, BarBreadcrumbs },

  head() {
    const title =
      this.$i18n.t('components.new_products.header') + ' - Air Conditioning Parts - AP Air Ltd.'
    const description = ''

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ]
    }
  },
  data() {
    return {
      page: 0,
      infiniteId: 0
    }
  },
  async asyncData({ app, query, store }) {
    const breadcrumbs = [
      {
        name: app.i18n.t('menu.home'),
        url: 'index'
      },
      {
        name: app.i18n.t('components.new_products.header'),
        url: {
          name: 'new-products'
        }
      }
    ]

    const products = await portal.get(`products/featured`)

    products.data.map((product) => {
      for (let j = 0; j < product.translations.length; j++) {
        if (product.translations[j].language.language_code === 'en') {
          product.fallback_product = product.translations[j]
        }
        if (
          product.translations[j].language.language_code ===
          store.state.language
        ) {
          product.product = product.translations[j]
        }
      }
      product.link = product.id + ' ' + product.sku + ' ' + product.product.name
    })

    return {
      breadcrumbs,
      products: products.data,
      totalProducts: products.data.length
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'Products',
        page_path: this.$route.fullPath
      })
    }

    if (process.client && this.$auth.loggedIn && !this.$store.getters.getIsFavoritesSet) {
      this.loadFavorites();
    }
  },
  methods: {
    changeView(view) {
      this.showView = view
    },
    loadFavorites () {
      portal.get(`favourites/get-all/${this.$auth.user.user_id}`)
      .then((response) => {
        this.$store.commit('SET_FAVORITE_ITEMS', response.data.products.map(item => item.product_id));
        this.$store.commit('SET_FAVORITE_ITEMS_FETCHED', true);
      });
    }
  }
}
</script>
